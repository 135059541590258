var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stageline-landing-page-component" } },
    [
      _vm.showNotification ? _c("notification-manager") : _vm._e(),
      !_vm.loaded ? _c("ct-centered-spinner") : _vm._e(),
      _vm.loaded
        ? _c(
            "div",
            { staticClass: "load-companies-container" },
            [
              _c(
                "div",
                { staticClass: "load-companies" },
                [
                  _c("manage-your-business-svg", {
                    staticClass: "img-desktop",
                    attrs: { alt: "Manage your business" },
                  }),
                  _c("manage-your-business-svg-box", {
                    staticClass: "img-mobile",
                    attrs: { alt: "Manage your business" },
                  }),
                  _c("select-business"),
                ],
                1
              ),
              _c("stageline-no-companies"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }